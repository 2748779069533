import { useState, useEffect } from "react";
import stl from "./AllowCookie.module.scss";
import Alert from "@/components/Alert/Alert";
import Cookies from "js-cookie";

const AllowCookie = () => {
  const [isAllowed, setIsAllowed] = useState(true);

  useEffect(() => {
    setIsAllowed(!!Cookies.get("allow-cookie"));
  }, []);

  const setAllowCookie = () => {
    Cookies.set("allow-cookie", "1", { expires: 365 });
    setIsAllowed(true);
  };

  if (!isAllowed) {
    return (
      <Alert>
        <div className={stl.inner}>
          <p className={stl.text}>
            Этот сайт использует куки-файлы и&nbsp;другие технологии, чтобы
            помочь вам в&nbsp;навигации,
            <br />
            а&nbsp;также предоставить лучший пользовательский опыт.
          </p>

          <button className="btn btn--secondary" onClick={setAllowCookie}>
            Принять
          </button>
        </div>
      </Alert>
    );
  } else return;
};

export default AllowCookie;
