export * from "./ShareIcon";
export * from "./TelegramIcon";
export * from "./VkIcon";
export * from "./WhatsAppIcon";
export * from "./OkIcon";
export * from "./LinkedInIcon";
export * from "./ArrowLeftIcon";
export * from "./ArrowRightIcon";
export * from "./YandexIcon";
export * from "./StarIcon";
