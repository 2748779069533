import stl from './Background.module.scss'

const Background = () => {

  return (
    <div className={ stl.box }>
      <div className={ stl.center }>
        <img
          className={ stl.img }
          src="img/splash-1.webp"
          alt="Background image 1"
        />

        <img
          className={ stl.img }
          src="img/splash-2.webp"
          alt="Background image 2"
          loading='lazy'
        />

        <img
          className={ stl.img }
          src="img/splash-3.webp"
          alt="Background image 3"
          loading='lazy'
        />
      </div>
    </div>
  )
}

export default Background