import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'
import PTLogo from '@/components/PTLogo/PTLogo'
import BurgerBtn from './BurgerBtn/BurgerBtn'
import PTNav from '@/components/PTNav/PTNav'
import stl from './BurgerMenu.module.scss'

const BurgerMenu = () => {
  const [isMenuShown, setIsMenuShown] = useState()
  const menu = useRef()

  const openMenu = () => {
    setIsMenuShown(true)
    document.body.classList.add('lock')
  }

  const closeMenu = () => {
    setIsMenuShown(false)
    document.body.classList.remove('lock')
  }

  const toggleMenu = () => {
    isMenuShown ? closeMenu() : openMenu()
  }

  return (
    <>
      <BurgerBtn
        className={ stl.btn }
        isMenuOpened={ isMenuShown }
        onClick={ toggleMenu }
      />

      <div className={`${stl.box} ${isMenuShown && stl.show}`} ref={ menu }>
        <PTNav
          layout='burger'
          onLinkClick={ closeMenu }
        />

        <Link
          className={`btn btn--primary ${ stl.btn_brief }`}
          href='/brief'
          onClick={ closeMenu }
        >
          Стать клиентом
        </Link>

        <a href="tel:8927703006">+7 927 270-30-06</a>

        <a href="mailto:a@proninteam.ru">a@proninteam.ru</a>

        <Link
          className={ stl.policy }
          href="/legal#privacy"
          onClick={ closeMenu }
        >
          Политика конфиденциальности
        </Link>
      </div>

      { isMenuShown && <div
        className={ stl.backdrop }
        onClick={ closeMenu }
      />}
    </>
  )
}

export default BurgerMenu