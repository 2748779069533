import Link from "next/link";
import stl from "./Footer.module.scss";
import Container from "@/components/Container/Container";
import PTLogo from "@/components/PTLogo/PTLogo";
import PTNav from "@/components/PTNav/PTNav";
import Contact from "./Contact/Contact";
import { Rating } from "../Rating";

const Footer = () => {
  return (
    <footer className={stl.box}>
      <Container className={stl.inner} types={["footer"]}>
        <div className={stl.main}>
          <PTLogo className={stl.logo} />ⓒ «Pronin Team»{" "}
          {new Date().getFullYear()}
          <Link className={stl.policy} href="/legal#privacy">
            Политика конфиденциальности
          </Link>
        </div>
        <div className={stl.rightPart}>
          <div className={stl.infoBlock}>
            <div className={stl.contacts}>
              <Contact link="tel:8927703006" linkText="+7 927 270-30-06" />
              <Contact
                link="mailto:a@proninteam.ru"
                linkText="a@proninteam.ru"
              />
            </div>
            {/*TODO: заменить моковые данные рейтинга */}
            <Rating
              rating={{ count: "5,0", starsCount: 5 }}
              className={stl.rating}
            />
          </div>

          <PTNav className={stl.nav} layout="footer" />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
