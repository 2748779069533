import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode } from "react";

interface PTHeadProps {
  title?: string;
  keywords?: string;
  description?: string;
  url?: string;
  ogTitle?: string;
  ogType?: string;
  ogImg?: string;
  ogLocale?: string;
  ogSiteName?: string;
  twitterCard?: string;
  twitterSite?: string;
  twitterImg?: string;
  children?: ReactNode;
}

function PTHead({
  title = "Разработка сайтов, интернет-магазинов и web-сервисов. ProninTeam — студия интернет-решений",
  keywords = "разработка сайтов, разработка интернет-магазинов, web-сервисы",
  description = "Разработка сайтов, интернет-магазинов, web-сервисов и мобильных приложений по AGILE и WATERFALL. Опытная команда для разработки проектов.",
  url,
  ogTitle,
  ogType = "website",
  ogImg = "/og.png",
  ogLocale = "ru_RU",
  ogSiteName = "ProninTeam",
  twitterCard = "summary_large_image",
  twitterSite,
  twitterImg,
  children,
}: PTHeadProps) {
  const router = useRouter();
  const currentURL = `https://proninteam.ru${router.asPath}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <link rel="canonical" href={url ?? currentURL} />

      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={url ?? currentURL} />
      <meta property="og:site_name" content={ogSiteName} />
      <meta property="og:title" content={ogTitle ?? title} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={ogImg} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:locale" content={ogLocale} />
      <meta property="og:locale:alternate" content="en_US" />

      <meta name="twitter:card" content={twitterCard} />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterImg ?? ogImg} />
      {children}
    </Head>
  );
}

export default PTHead;
