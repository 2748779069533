import clsx from "clsx";
import { StarIcon, YandexIcon } from "../SVG";
import styles from "./styles.module.scss";

interface RatingProps {
  className?: string;
  rating: {
    count: string;
    starsCount: number;
  };
}

const MAX_STARS = 5;

export const Rating = ({ className, rating }: RatingProps) => {
  return (
    <div className={clsx(styles.rating, className)}>
      <div className={styles.rating__mainInfo}>
        <YandexIcon />
        <p className={styles.ratingCount}>{rating.count}</p>
        <ul className={styles.rating__starsList}>
          {new Array(MAX_STARS).fill(0).map((_, index) => {
            const isActive = index < rating.starsCount;
            return (
              <li key={`star-${index}`}>
                <StarIcon color={isActive ? "var(--yellow)" : ""} />
              </li>
            );
          })}
        </ul>
      </div>
      <p className={styles.rating__signature}>Рейтинг организации в Яндексе</p>
    </div>
  );
};
