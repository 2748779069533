import { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '@/utils/Contexts'
import stl from './ThemeSwitcher.module.scss'
import Cookies from 'js-cookie'

const ThemeSwitcher = () => {
  const [curTheme, setCurTheme] = useState(useContext(ThemeContext))

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', curTheme)
  }, [curTheme])

  // Change theme
  const toggleTheme = () => {
    const newTheme = curTheme === 'dark' ? 'light' : 'dark'
    Cookies.set('pt-sitetheme', newTheme, { expires: 365 })
    setCurTheme(newTheme)
  }

  return (
    <button
      className={ stl[`switch--${curTheme}`] }
      onClick={ toggleTheme }
      aria-label="Сменить тему сайта"
    />
  )
}

export default ThemeSwitcher