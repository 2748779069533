import Link from "next/link";
import stl from "./PTNav.module.scss";

const PTNav = ({ className, layout = "header", onLinkClick }) => {
  const links = [
    ["Услуги", "/#services"],
    ["Кейсы", "/cases"],
    ["Команда", "/team"],
    // ["Блог", "/blog"],
  ];

  return (
    <nav className={`${stl.list} ${stl[layout]} ${className || ""}`}>
      {links.map((link, index) => {
        const [text, href] = [...link];

        return (
          <Link
            key={`link-${index}`}
            className={stl.link}
            href={href}
            onClick={onLinkClick}>
            {text}
          </Link>
        );
      })}
    </nav>
  );
};

export default PTNav;
