import Container from '@/components/Container/Container'
import stl from './Alert.module.scss'

const Alert = ({ children }) => {

  return (
    <div className={ stl.box }>
      <Container>
        { children }
      </Container>
    </div>
  )
}

export default Alert