interface VkIconProps {
  className?: string;
  color?: string;
}

export const VkIcon = ({ className, color }: VkIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color || "var(--body-bg)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.4582 16.7625C8.18744 16.7625 5.7515 13.8299 5.65 8.95H7.78929C7.85956 12.5317 9.43666 14.0488 10.6859 14.3617V8.95H12.7003V12.039C13.9339 11.9061 15.2298 10.4984 15.6671 8.95H17.6815C17.3457 10.8582 15.9404 12.2658 14.941 12.8445C15.9404 13.3137 17.541 14.5415 18.15 16.7625H15.9326C15.4563 15.2766 14.2697 14.1271 12.7003 13.9706V16.7625H12.4582Z" />
    </svg>
  );
};
