interface WhatsAppIconProps {
  className?: string;
  color?: string;
}

export const StarIcon = ({ className, color }: WhatsAppIconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color || "var(--theme-switch-color)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.4825 6.03472L10.2918 5.55951L8.53193 1.83165C8.43652 1.62955 8.22874 1.5 8 1.5C7.77125 1.5 7.56347 1.62955 7.46806 1.83165L5.70821 5.55952L1.51749 6.03473C1.29029 6.0605 1.09935 6.21258 1.02867 6.42407C0.957982 6.63556 1.02051 6.86771 1.18874 7.01838L4.29179 9.79751L3.46164 13.8191C3.41664 14.0371 3.50642 14.2606 3.69147 14.3913C3.87653 14.522 4.12295 14.536 4.32234 14.427L8 12.4167L11.6776 14.427C11.877 14.536 12.1235 14.522 12.3085 14.3913C12.4936 14.2606 12.5833 14.0371 12.5383 13.819L11.7082 9.7975L14.8113 7.01837C14.9795 6.8677 15.042 6.63555 14.9713 6.42406C14.9006 6.21256 14.7097 6.06048 14.4825 6.03472Z" />
    </svg>
  );
};
