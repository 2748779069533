export const YandexIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2405_5124)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#FC3F1D"
        />
        <path
          d="M13 17H10.896V5.75972H9.95865C8.24055 5.75972 7.3406 6.6876 7.3406 8.07271C7.3406 9.64416 7.96132 10.3722 9.24544 11.3001L10.3037 12.0705L7.26234 16.9981H5L7.73722 12.5988C6.16318 11.3847 5.27746 10.1993 5.27746 8.1995C5.27746 5.70016 6.89062 4 9.94442 4H12.9858V16.9962H13V17Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2405_5124">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
