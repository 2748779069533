interface ArrowRightIconProps {
  className?: string;
  color?: string;
}

export const ArrowRightIcon = ({ className, color }: ArrowRightIconProps) => {
  return (
    <svg
      className={className}
      width="56"
      height="48"
      viewBox="0 0 56 48"
      fill={color || "var(--text-secondary)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="48" fill="white" fillOpacity="0.04" />
      <path d="M43.3536 24.3536C43.5488 24.1583 43.5488 23.8417 43.3536 23.6464L40.1716 20.4645C39.9763 20.2692 39.6597 20.2692 39.4645 20.4645C39.2692 20.6597 39.2692 20.9763 39.4645 21.1716L42.2929 24L39.4645 26.8284C39.2692 27.0237 39.2692 27.3403 39.4645 27.5355C39.6597 27.7308 39.9763 27.7308 40.1716 27.5355L43.3536 24.3536ZM13 24.5H43V23.5H13V24.5Z" />
    </svg>
  );
};
