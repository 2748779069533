interface ArrowLeftIconProps {
  className?: string;
  color?: string;
}

export const ArrowLeftIcon = ({ className, color }: ArrowLeftIconProps) => {
  return (
    <svg
      className={className}
      width="56"
      height="48"
      viewBox="0 0 56 48"
      fill={color || "var(--text-secondary)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="48"
        transform="matrix(-1 0 0 1 56 0)"
        fill="white"
        fillOpacity="0.04"
      />
      <path d="M12.6464 24.3536C12.4512 24.1583 12.4512 23.8417 12.6464 23.6464L15.8284 20.4645C16.0237 20.2692 16.3403 20.2692 16.5355 20.4645C16.7308 20.6597 16.7308 20.9763 16.5355 21.1716L13.7071 24L16.5355 26.8284C16.7308 27.0237 16.7308 27.3403 16.5355 27.5355C16.3403 27.7308 16.0237 27.7308 15.8284 27.5355L12.6464 24.3536ZM43 24.5H13V23.5H43V24.5Z" />
    </svg>
  );
};
