interface WhatsAppIconProps {
  className?: string;
  color?: string;
}

export const WhatsAppIcon = ({ className, color }: WhatsAppIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color || "var(--body-bg)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1091 17.6909C15.3628 17.6909 18 15.0739 18 11.8455C18 8.61707 15.3631 6 12.1091 6C8.85547 6 6.218 8.61707 6.218 11.8455C6.218 12.9499 6.52693 13.9829 7.06333 14.8637L6 18L9.26147 16.9636C10.1057 17.4269 11.0761 17.6909 12.1091 17.6909ZM14.8853 13.0625C14.8611 13.0509 14.835 13.0384 14.8071 13.0245C14.6626 12.9529 13.9525 12.6061 13.8205 12.5585C13.6881 12.5108 13.5915 12.4868 13.4954 12.6301C13.3993 12.7736 13.1225 13.0961 13.0381 13.1917C12.9538 13.2876 12.8697 13.2996 12.7251 13.2279C12.7016 13.2162 12.6696 13.2024 12.63 13.1854C12.4269 13.098 12.0258 12.9253 11.5641 12.5168C11.1349 12.1371 10.845 11.6683 10.7607 11.5247C10.6766 11.3813 10.7519 11.3039 10.8241 11.2325C10.8676 11.1896 10.9175 11.1292 10.9674 11.069C10.9921 11.0391 11.0168 11.0093 11.0407 10.9816C11.1027 10.9099 11.1292 10.8559 11.1656 10.7817C11.1717 10.7693 11.1781 10.7563 11.185 10.7425C11.2334 10.6469 11.2093 10.5633 11.173 10.4915C11.1485 10.4428 11.0072 10.1017 10.8814 9.79802C10.8221 9.65476 10.7662 9.51984 10.7277 9.42787C10.6218 9.17549 10.516 9.1823 10.4346 9.18754C10.4236 9.18826 10.413 9.18894 10.4029 9.18894C10.3622 9.18894 10.3187 9.18614 10.2737 9.18324C10.2256 9.18014 10.1758 9.17694 10.1261 9.17694C10.0298 9.17694 9.87326 9.21281 9.74086 9.35614C9.73236 9.36537 9.72285 9.37543 9.71252 9.38638C9.56217 9.54568 9.23566 9.89161 9.23566 10.5512C9.23566 10.8405 9.32271 11.1257 9.43038 11.3681C9.58488 11.716 9.78183 11.9757 9.82502 12.0326L9.82526 12.0329C9.83074 12.0402 9.84113 12.0555 9.8563 12.0778C10.0411 12.3498 10.9353 13.6658 12.2919 14.196C13.5086 14.6713 13.717 14.6212 13.9034 14.5765C13.9419 14.5673 13.9795 14.5582 14.0249 14.5541C14.2894 14.5303 14.879 14.2076 14.9998 13.8732C15.1198 13.5383 15.1198 13.2515 15.0839 13.1917C15.0546 13.1437 14.9863 13.1109 14.8853 13.0625Z"
      />
    </svg>
  );
};
