import styled from 'styled-components'
import LoaderSVG from '@/components/SVG/LoaderSVG'

const Box = styled.div`
  position: fixed;
  inset: 0 0 0 0;
  z-index: 999999;
  transition: opacity 0.5s ease-out;
  background-color: #333333;

  svg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: auto;
  }
`

const Preloader = () => {
  return (
    <Box>
      <LoaderSVG />
    </Box>
  )
}

export default Preloader