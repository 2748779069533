interface OkIconProps {
  className?: string;
  color?: string;
}

export const OkIcon = ({ className, color }: OkIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color || "var(--body-bg)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.1 7.925C12.7934 7.925 13.3573 8.48896 13.3573 9.18232C13.3573 9.87324 12.7934 10.4372 12.1 10.4372C11.4066 10.4372 10.8427 9.87324 10.8427 9.18232C10.8427 8.48896 11.4066 7.925 12.1 7.925ZM12.1 12.3049C13.8236 12.3049 15.2226 10.906 15.2226 9.18232C15.2226 7.45869 13.8236 6.05732 12.1 6.05732C10.3764 6.05732 8.975 7.45869 8.975 9.18232C8.975 10.9035 10.3764 12.3049 12.1 12.3049ZM13.328 14.6926C13.9457 14.5534 14.5414 14.3093 15.0907 13.9626C15.5058 13.7014 15.6303 13.1521 15.369 12.737C15.1078 12.322 14.5585 12.195 14.1435 12.4587C12.8983 13.2399 11.2992 13.2399 10.0565 12.4587C9.63906 12.195 9.08975 12.322 8.82852 12.737C8.56729 13.1521 8.6918 13.7014 9.10928 13.9626C9.65615 14.3093 10.2543 14.5534 10.872 14.6926L9.17275 16.3918C8.82607 16.7385 8.82607 17.3024 9.1752 17.6491C9.34853 17.8225 9.57559 17.9104 9.80264 17.9104C10.0297 17.9104 10.2592 17.8225 10.4325 17.6491L12.1 15.9816L13.7699 17.6491C14.1166 17.9958 14.6781 17.9958 15.0272 17.6491C15.3739 17.3024 15.3739 16.7385 15.0272 16.3918L13.328 14.6926Z" />
    </svg>
  );
};
