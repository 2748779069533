export default function LogoSVG({ className }) {

  return (
    <svg
      className={ className }
      width="200"
      height="40"
      viewBox="0 0 200 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_340)">
        <path d="M40.0989 0V40H0V26.6667H26.7326V0H40.0989Z"/>
        <path d="M66.8315 0H53.4652V40H66.8315V0Z"/>

        <path d="M13.3663 0H0V13.3333H13.3663V0Z" />

        <path d="M90.2493 39.9999H86.6003V29.0133H82.3364V26.2666H94.4998V29.0133H90.2493V39.9999Z"/>
        <path d="M116.287 39.9999H103.856V26.2666H116.287V29.0133H107.893V31.6666H114.496V34.4133H107.893V37.2533H116.287V39.9999Z"/>
        <path d="M129.065 39.9999H125.643L130.295 26.2666H133.81L138.462 39.9999H135.04L134.251 37.5466H129.854L129.065 39.9999ZM130.656 34.9999H133.463L132.059 30.3866L130.656 34.9999Z"/>
        <path d="M157.054 32.3466L156.319 34.7999L155.57 37.2533H153.231L151.641 32.3466L150.892 39.9866H147.871V26.2666H151.707L154.408 33.5199L157.054 26.2666H160.944V39.9866H157.923L157.054 32.3466Z"/>
        <path d="M82.3364 0H90.5701C91.9468 0 92.9359 0.32 93.5508 0.946667C94.179 1.56 94.4864 2.56 94.4864 3.93333V5.89333C94.4864 7.26667 94.179 8.26667 93.5508 8.89333C92.9359 9.50667 91.9468 9.81333 90.5701 9.81333H85.8651V13.7333H82.3364V0ZM90.9711 3.92C90.9711 3.13333 90.5834 2.74667 89.7948 2.74667H85.8651V7.06667H89.7948C90.5834 7.06667 90.9711 6.68 90.9711 5.89333V3.92Z"/>
        <path d="M103.856 0H112.143C113.52 0 114.523 0.32 115.137 0.946667C115.766 1.56 116.086 2.56 116.086 3.93333V5.69333C116.086 6.77333 115.899 7.62667 115.538 8.22667C115.177 8.82667 114.616 9.22667 113.827 9.42667L116.287 13.7333H112.437L110.165 9.61333H107.398V13.7333H103.856V0ZM112.531 3.92C112.531 3.13333 112.143 2.74667 111.355 2.74667H107.412V6.86667H111.355C112.143 6.86667 112.531 6.48 112.531 5.69333V3.92Z"/>
        <path d="M134.919 3.81333C134.919 3.05333 134.518 2.66667 133.73 2.66667H130.375C129.586 2.66667 129.185 3.05333 129.185 3.81333V9.92C129.185 10.68 129.586 11.0667 130.375 11.0667H133.73C134.518 11.0667 134.919 10.68 134.919 9.92V3.81333ZM138.475 9.92C138.475 11.2533 138.154 12.2267 137.526 12.84C136.911 13.44 135.909 13.7333 134.518 13.7333H129.586C128.21 13.7333 127.194 13.44 126.566 12.84C125.951 12.2267 125.643 11.2533 125.643 9.92V3.81333C125.643 2.48 125.951 1.50667 126.566 0.92C127.194 0.306667 128.21 0 129.586 0H134.518C135.895 0 136.898 0.306667 137.526 0.92C138.154 1.52 138.475 2.48 138.475 3.81333V9.92Z"/>
        <path d="M177.464 13.7333H170.38V10.9867H172.145V2.74667H170.38V0H177.464V2.74667H175.7V10.9867H177.464V13.7333Z"/>
        <path d="M160.944 13.7465H156.867L151.708 5.51186V13.7465H147.831V0.0131836H151.908L157.067 8.24785V0.0131836H160.93V13.7465H160.944Z"/>
        <path d="M200 13.7333H195.923L190.764 5.50667V13.7333H186.901V0H190.978L196.137 8.22667V0H200V13.7333Z"/>
        <rect x="147.831" y="26.2666" width="3.87623" height="13.7333"/>
        <rect x="157.054" y="26.2666" width="3.87623" height="13.7333"/>
      </g>
      <defs>
        <clipPath id="clip0_1_340">
          <rect width="200" height="40"/>
        </clipPath>
      </defs>
    </svg>
  )
}