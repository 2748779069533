interface LinkedInIconProps {
  className?: string;
  color?: string;
}

export const LinkedInIcon = ({ className, color }: LinkedInIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color || "var(--body-bg)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 17H15.2398V13.1504C15.2398 12.095 14.8388 11.5052 14.0034 11.5052C13.0946 11.5052 12.6198 12.119 12.6198 13.1504V17H10.4417V9.66667H12.6198V10.6545C12.6198 10.6545 13.2747 9.44262 14.8309 9.44262C16.3864 9.44262 17.5 10.3925 17.5 12.357V17ZM7.84313 8.70643C7.1012 8.70643 6.5 8.1005 6.5 7.35321C6.5 6.60592 7.1012 6 7.84313 6C8.58506 6 9.1859 6.60592 9.1859 7.35321C9.1859 8.1005 8.58506 8.70643 7.84313 8.70643ZM6.71842 17H8.98968V9.66667H6.71842V17Z"
      />
    </svg>
  );
};
