import { ReactNode } from "react";
import stl from "./Container.module.scss";

interface ContainerProps {
  className?: string;
  types?: string[];
  children: ReactNode;
}

const Container = ({ children, className, types = [] }: ContainerProps) => {
  const getClassName = () => {
    let result = stl.box;

    if (types.length) {
      result +=
        " " +
        types.reduce((res, type, i) => {
          return i === 0 ? stl[type] : res + " " + stl[type];
        }, "");
    }

    if (className) result += " " + className;

    return result;
  };

  return <div className={getClassName()}>{children}</div>;
};

export default Container;
