import { useState, useEffect} from 'react'
import Container from '@/components/Container/Container'
import PTLogo from '@/components/PTLogo/PTLogo'
import PTNav from '@/components/PTNav/PTNav'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import ThemeSwitcher from './ThemeSwitcher/ThemeSwitcher'
import stl from './Header.module.scss'

const Header = () => {
  const [appWidth, setAppWidth] = useState()

  useEffect(() => {
    const handleResize = () => {
      setAppWidth(window.innerWidth)
      if (window.innerWidth > 768) document.body.classList.remove('lock')
    }
    setAppWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  return(
    <header className={ stl.box }>
      <Container>
        <div className={ stl.inner }>
          <PTLogo className={ stl.logo } isAnimate={ true } />

          {
            appWidth > 768
            ? <PTNav className={ stl.nav } layout='header' />
            : <BurgerMenu />
          }

          <ThemeSwitcher />
        </div>
      </Container>
    </header>
  )
}

export default Header