import stl from './Contact.module.scss'

const Contact = ({ label, link, linkText }) => {

  return (
    <div className={ stl.group }>
      { label }
      <a href={ link } className={ stl.link }>
        { linkText }
      </a>
    </div>
  )
}

export default Contact
