interface ShareIconSVGProps {
  size?: 28 | 44;
  className?: string;
  color?: string;
}

export function ShareIconSVG({
  color,
  size = 28,
  className,
}: ShareIconSVGProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill={color || 'var(--text-secondary)'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1022 6.2225C16.1022 4.07583 17.8522 2.334 20.0082 2.334C20.5201 2.33277 21.0272 2.43237 21.5006 2.62713C21.9739 2.82188 22.4043 3.10796 22.7671 3.46904C23.1299 3.83012 23.4181 4.25913 23.6151 4.73157C23.8121 5.20401 23.9141 5.71062 23.9154 6.2225C23.9154 8.37033 22.1654 10.1122 20.0082 10.1122C19.4909 10.1128 18.9786 10.0106 18.5011 9.81153C18.0236 9.61244 17.5905 9.32044 17.2269 8.9525L11.8194 12.6345C11.97 13.3847 11.8961 14.1626 11.607 14.871L17.536 18.7677C18.2344 18.1981 19.1082 17.8877 20.0094 17.8892C20.5212 17.8881 21.0283 17.9878 21.5016 18.1827C21.9749 18.3776 22.4052 18.6638 22.7679 19.025C23.1307 19.3862 23.4187 19.8153 23.6155 20.2878C23.8124 20.7603 23.9143 21.267 23.9154 21.7788C23.9154 23.9255 22.1654 25.6673 20.0082 25.6673C18.9746 25.6695 17.9825 25.2611 17.25 24.5319C16.5175 23.8027 16.1047 22.8124 16.1022 21.7788C16.1013 21.2336 16.2162 20.6943 16.4394 20.1968L10.557 16.334C9.84468 16.9534 8.93201 17.2937 7.98803 17.2918C7.47612 17.2931 6.96898 17.1934 6.49559 16.9986C6.0222 16.8037 5.59185 16.5175 5.22911 16.1563C4.86638 15.7951 4.57837 15.3659 4.38155 14.8934C4.18473 14.4208 4.08295 13.9141 4.08203 13.4022C4.0831 12.8903 4.18499 12.3838 4.38188 11.9113C4.57877 11.4389 4.8668 11.0099 5.22952 10.6488C5.59224 10.2877 6.02255 10.0016 6.49586 9.80682C6.96917 9.61205 7.47622 9.51244 7.98803 9.51366C9.22937 9.51366 10.333 10.0888 11.0482 10.9848L16.29 7.416C16.1653 7.03045 16.1019 6.62771 16.1022 6.2225Z"
      />
    </svg>
  );
}
