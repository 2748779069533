interface TelegramIconProps {
  className?: string;
  color?: string;
}
export const TelegramIcon = ({ className, color }: TelegramIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color || "var(--body-bg)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.52656 11.8944C9.44219 10.6244 11.3859 9.78703 12.3578 9.3825C15.1359 8.22734 15.7125 8.02672 16.0891 8.01992C16.1719 8.01859 16.3563 8.03906 16.4766 8.13633C16.5766 8.21836 16.6047 8.3293 16.6187 8.40719C16.6312 8.485 16.6484 8.66234 16.6344 8.80078C16.4844 10.382 15.8328 14.2192 15.5016 15.9903C15.3625 16.7397 15.0859 16.9909 14.8188 17.0155C14.2375 17.0689 13.7969 16.6317 13.2344 16.2631C12.3547 15.6861 11.8578 15.327 11.0031 14.7641C10.0156 14.1134 10.6562 13.7558 11.2187 13.1714C11.3656 13.0184 13.925 10.6911 13.9734 10.48C13.9797 10.4536 13.9859 10.3552 13.9266 10.3033C13.8688 10.2513 13.7828 10.2691 13.7203 10.2831C13.6313 10.3031 12.2266 11.2325 9.50156 13.0711C9.10312 13.3452 8.74219 13.4787 8.41719 13.4717C8.06094 13.4641 7.37344 13.2698 6.8625 13.1039C6.2375 12.9003 5.73906 12.7927 5.78281 12.4469C5.80469 12.2669 6.05313 12.0827 6.52656 11.8944Z" />
    </svg>
  );
};
