import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect, useContext } from "react";
import Preloader from "@/components/Preloader/Preloader";
import PTHead from "@/components/PTHead/PTHead";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Background from "@/components/Background/Background";
import AllowCookie from "@/components/AllowCookie/AllowCookie";
import { unbounded, golosText, monaco } from "@/utils/fonts";
import Container from "@/components/Container/Container";
import "@/styles/globals.scss";
import { ThemeContext } from "@/utils/Contexts.js";
import Cookies from "js-cookie";

export default function App({ Component, pageProps }) {
  const [loading, setLoading] = useState(true);
  const [pageIsReady, setPageIsReady] = useState(false);
  const [theme, setTheme] = useState(useContext(ThemeContext));

  const router = useRouter();

  const briefAllowPaths = [
    "/",
    "/team",
    "/cases",
    "/cases/[case]",
    "/[user]",
    "/blog",
    "/blog/[post]",
  ];

  const backgroundAllowPaths = [
    "/",
    "/team",
    "/cases",
    "/blog",
    "/blog/[post]",
  ];

  useEffect(() => {
    document.body.className = `${unbounded.variable} ${golosText.variable} ${monaco.variable}`;
    document.documentElement.style.background = "transparent";
    setTimeout(() => setLoading(false), 500);
  }, []);

  useEffect(() => {
    setTheme(Cookies.get("pt-sitetheme") || "dark"); 
  }, [loading]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    setTimeout(() => setPageIsReady(true), 0);
  }, [theme]);

  return (
    <>
      <PTHead
        title={pageProps.title}
        keywords={pageProps.keywords}
        description={pageProps.description}
        url={pageProps.url}
        ogTitle={pageProps.ogTitle}
        ogType={pageProps.ogType}
        ogImg={pageProps.ogImg}
        ogLocale={pageProps.ogLocale}
        ogSiteName={pageProps.ogSiteName}
        twitterCard={pageProps.twitterCard}
        twitterSite={pageProps.twitterSite}
        twitterImg={pageProps.twitterImg}
      />

      {loading || !pageIsReady ? (
        <Preloader />
      ) : (
        <ThemeContext.Provider value={theme}>
          <Header />

          <main>
            <Component {...pageProps} />

            {briefAllowPaths.includes(router.pathname) &&
              !pageProps.errorCode && (
                <Container types={["brief"]}>
                  <Link className="btn btn--primary btn--brief" href="/brief">
                    Стать клиентом
                  </Link>
                </Container>
              )}
          </main>

          <Footer />

          {backgroundAllowPaths.includes(router.pathname) &&
            !pageProps.errorCode && <Background />}

          <AllowCookie />
        </ThemeContext.Provider>
      )}
    </>
  );
}
