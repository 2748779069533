import Link from 'next/dist/client/link'
import LogoSVG from '@/components/SVG/LogoSVG'
import stl from './PTLogo.module.scss'
import { getClassName } from '@/utils/className.ts'

const PTLogo = ({ className = '', isAnimate = false }) => {

  return (
    <Link
      className={ getClassName([stl.link], className) }
      href="/"
      aria-label="Главная страница"
    >
      <LogoSVG className={ `logo--main ${ isAnimate ? 'logo--animate' : '' }` } />
    </Link>
  )
}

export default PTLogo