import stl from './BurgerBtn.module.scss'

const BurgerBtn = ({ className, isMenuOpened, onClick }) => {

  const getClassName = () => {
    return stl.btn + (className
      ? ' ' + className
      : '')
  }

  return (
    <button
      className={`${getClassName()} ${isMenuOpened ? stl.opened : ''}`}
      onClick={ onClick }
    >
      <span/>
      <span/>
      <span/>
    </button>
  )
}

export default BurgerBtn